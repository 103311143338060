import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Dropdown, Checkbox, Input, Button, Form } from 'semantic-ui-react';


const FilesForm = ({ lookupData, onFormSubmit, clearForm }) => {

    
    const initialValues = {
        buyerShortCode: [],
        charge: [],
        type: [],
        lastUpdateInVp: '',
        isDateFrom: false,
        IAN: ''
    };

    const validationSchema = Yup.object().shape({
        lastUpdateInVp: Yup.date().nullable(true),
        IAN: Yup.string()
                .matches(/^(\d{6}(,\d{6})*)?$/, 'IAN muss 6-stellig sein und durch Komma getrennt werden.')
                .nullable(true)
        });

    const handleFormSubmit = (values) => {
        onFormSubmit(values);
    };

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleFormSubmit}>
            {({ setFieldValue, errors, touched, handleSubmit, values, isSubmitting, resetForm }) => (
                <Form onSubmit={handleSubmit}>
                    {/* <pre>{JSON.stringify(values)}</pre> */}
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Charge</label>
                            <Dropdown
                                fluid
                                selection
                                multiple
                                value={values.charge}
                                options={lookupData.listCharges.map(charge => ({ key: charge, value: charge, text: charge }))}
                                onChange={(e, { value }) => setFieldValue('charge', value)}
                                error={touched.charge && Boolean(errors.charge)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Einkäufer</label>
                            <Dropdown
                                fluid
                                selection
                                multiple
                                value={values.buyerShortCode}
                                options={lookupData.listPurchasers.map(purchaser => ({ key: purchaser.shortCode, value: purchaser.shortCode, text: purchaser.lookupText }))}
                                onChange={(e, { value }) => setFieldValue('buyerShortCode', value)}
                                error={touched.buyerShortCode && Boolean(errors.buyerShortCode)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Type</label>
                            <Dropdown
                                fluid
                                selection
                                multiple
                                value={values.type}
                                options={lookupData.listSearchTypes.map(charge => ({ key: charge.portal, value: charge.portal, text: charge.frontend }))}
                                onChange={(e, { value }) => setFieldValue('type', value)}
                                error={touched.type && Boolean(errors.type)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>IAN</label>
                            <Field name="IAN" as={Input} placeholder="IAN" />
                            {touched.IAN && errors.IAN && <div className="field-error">{errors.IAN}</div>}
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Report Date</label>
                        <Field name="lastUpdateInVp">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    type="date"
                                    error={touched.lastUpdateInVp && Boolean(errors.lastUpdateInVp)}
                                />
                            )}
                        </Field>
                    </Form.Field>

                    <Form.Field>
                        <Checkbox
                            checked={values.isDateFrom}
                            label="Ab Datum (J/N)"
                            onChange={(e, { checked }) => setFieldValue('isDateFrom', checked)} />
                    </Form.Field>


                    <Button type="submit" color="blue" loading={isSubmitting}>Submit</Button>
                    <Button type="button" color="red" onClick={() => {
                        //console.log('resetting form initialValues:', initialValues);
                        resetForm(initialValues);
                        clearForm();
                    }}>Clear</Button>
                </Form>
            )}
        </Formik>
    );
};

export default FilesForm;
