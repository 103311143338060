import React from 'react'
import { Button, Divider, Table } from 'semantic-ui-react'


const BriefingDocumentsTable = ({ documentsList, onDownloadRequest }) => {
    return (
        <div className="my-gap">
            <Table className="ui celled table small compact">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Dateiname</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Erstellt</Table.HeaderCell>
                        <Table.HeaderCell>Fehler</Table.HeaderCell>
                        <Table.HeaderCell>Download</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        documentsList.map((doc, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{doc.fileName}</Table.Cell>
                                    <Table.Cell>{doc.status}</Table.Cell>
                                    <Table.Cell>{doc.created}</Table.Cell>
                                    <Table.Cell>{doc.error===""?"-":doc.error}</Table.Cell>
                                    <Table.Cell>
                                        {
                                            doc.status === "done" ? 
                                            <Button positive onClick={() => onDownloadRequest(doc.documentId, doc.documentKey, doc.fileName)}>Download</Button> :
                                            <Button disabled>N/A</Button>
                                        }
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

export default BriefingDocumentsTable