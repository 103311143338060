import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updatePassword } from "firebase/auth";
import { useUserAuth } from '../../context/UserAuthContext';
import FormikTextInput from './FormikTextInput';
import { Button, Form, Header, Message, Segment } from 'semantic-ui-react';


const validationSchema = Yup.object({
    passwordOne: Yup.string()
        .required("Required"),
    passwordTwo: Yup.string()
        .oneOf([Yup.ref('passwordOne'), null], "Passwords must match")
        .required("Required")
});

const PasswordChange = () => {
    const { user } = useUserAuth();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = async (values, setSubmitting, resetForm) => {     
        setSuccess(false);    
        setError(null);
        try {
            await updatePassword(user, values.passwordOne);
            resetForm();
            setSubmitting(false);
            setSuccess(true);
            setError(null);
        } catch (error) {
            setSubmitting(false);
            setSuccess(false);
            setError(error.message);
        }
    };


    return (
        <Segment clearing padded="very">
            <Formik
                initialValues={{ passwordOne: '', passwordTwo: '' }}
                validationSchema={validationSchema}
            >
                {({ isSubmitting, setSubmitting, resetForm, values }) => (
                    <Form onSubmit={() => handleSubmit(values, setSubmitting, resetForm)}>
                        <Header as="h4" textAlign="left">Kennwort aktualisieren</Header>
                        <FormikTextInput name="passwordOne" label="Kennwort 1" placeholder="neue Kennwort" type="password" />
                        <FormikTextInput name="passwordTwo" label="Kennwort 2" placeholder="neue Kennwort bestätigen" type="password" />

                        <Button type="submit" positive loading={isSubmitting}>Reset</Button>
                    </Form>
                )}
            </Formik>
            {success && <Message success header='Erfolgreich' content="Ihr Passwort wurde erfolgreich aktualisiert." />}
            {error && <Message negative header='Erfolgreich fehlgeschlagen' content={error} />}
        </Segment >
    );
};

export default PasswordChange;
