import React from 'react'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Navbar from './components/navigation/NavBar'
import { Container } from 'semantic-ui-react'
import Home from './pages/Home'
import ProtectedRoute from './components/navigation/ProtectedRoute'
import ScrollToTop from './components/layout/ScrollToTop'
import UserProfile from './pages/UserProfile'
import Login from './pages/Login'
import ZipRequestsOverview from './pages/ZipRequestsOverview';
import NewZipRequest from './pages/NewZipRequest';
import Tester from './pages/Tester';
import DocumentTranslationOverView from './pages/DocumentTranslationOverView';

const MainApp = () => {
  return (
    <div>
        <Router>
            <Navbar />
            <Container style={{ marginTop: '7em', paddingLeft: '2em', paddingRight: '2em', paddingBottom: '7em' }} fluid>
                <Routes>
                    <Route path='/' element={<ProtectedRoute><NewZipRequest /></ProtectedRoute>} />
                    <Route path='/profile' element={<ProtectedRoute><UserProfile /></ProtectedRoute>} />
                    <Route path='/ziprequests/tabelle' element={<ProtectedRoute><ZipRequestsOverview /></ProtectedRoute>} />
                    <Route path='/ziprequests/neu' element={<ProtectedRoute><NewZipRequest /></ProtectedRoute>} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/activity' element={<ProtectedRoute><Tester /></ProtectedRoute>} />
                    <Route path='/translation' element={<ProtectedRoute><DocumentTranslationOverView /></ProtectedRoute>} />
                    <Route path='/tester' element={<ProtectedRoute><Tester /></ProtectedRoute>} />
                </Routes>
            </Container>
            <ScrollToTop />
        </Router>
    </div>
  )
}

export default MainApp