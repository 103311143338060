import React, { useEffect, useRef, useState } from 'react'
import LoadingComponent from '../components/layout/LoadingComponent';
import getEndpoints from '../api/endpoints';
import ZipRequestsTable from '../components/tables/ZipRequestsTable';
import ContentHeading from '../components/layout/ContentHeading';
import axios from 'axios';

const ZipRequestsOverview = () => {
    
    document.title = "Report Status";

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [downloadProgress, setDownloadProgress] = React.useState(0);

    const cancelTokenSource = useRef(null);

    const fetchData = async () => {
        try {
            setLoading(true);
            setData(null);
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getZipRequestsTable();
            setData(data);
        } catch (error) {
            alert("Data load error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const onDeleteZipRequest = async (zipId) => {
        try {    
            setLoading(true);        
            const Endpoints = await getEndpoints();
            await Endpoints.deleteZipRequest(zipId);
            await fetchData(); 
        } catch (error) {
            alert(`Error deleting ziprequest with id ${zipId}`);
        }finally {
            setLoading(false);
        }
    }

    const onDownloadZipRequest = async (zipId) => {
        alert(`Downloading zip with id ${zipId}`);
        try {
            setLoading(true);
            cancelTokenSource.current = axios.CancelToken.source();
            const Endpoints = await getEndpoints();
            const response = await Endpoints.getZipFileDownload(zipId, setDownloadProgress, cancelTokenSource.current.token);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const fileName = `files_${zipId}.zip`;    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }  catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.log(`Error getting file: ${error}`);
            }
        } finally {
            setDownloadProgress(0);
            setLoading(false);
        }
    }

    useEffect(() => {
        return () => {
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel('Component unmounted: Download cancelled');
            }
        };
    }, []);
    

    if (loading) {
        return <LoadingComponent />
    }

    return (
        <div>
            <ContentHeading title='Übersicht Downloads' />
            <ZipRequestsTable tableData={data} onDeleteZipRequest={onDeleteZipRequest} onDownloadZipRequest={onDownloadZipRequest}/>
        </div>
    )

}

export default ZipRequestsOverview