import React from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Container, Dropdown, Icon, Menu } from 'semantic-ui-react'
import { useUserAuth } from '../../context/UserAuthContext';


const Navbar = () => {
    const navigate = useNavigate();
    const { logOut, user } = useUserAuth();

    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/login");
        } catch (err) {
            alert(err)
        }
    }


    const getLoggedInComponent = () => {
        return (
            <>
                <Menu.Item as={NavLink} to="/profile">
                    <div className='nav-profile'>
                        <span>{user.displayName ? user.displayName : "Profile"}</span>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <Button negative content='Logout' onClick={handleLogout} />
                </Menu.Item>
            </>
        )
    }

    return (
        <Menu inverted fixed="top">
            <Container fluid>
                <Menu.Item as={Link} to='/' header>
                    <Icon name='code' size='large' />
                </Menu.Item>
                <Menu.Item as={Link} to='/' header>
                    <h3>Portal Manager</h3>
                </Menu.Item>
                {
                    user &&
                    <>
                        <Dropdown item text="Übersetzungen">
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate('/translation')} >Übersicht</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                }
                {
                    user &&
                    <>
                        <Dropdown item text="Dateien">
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => navigate('/ziprequests/tabelle')} >Tabelle</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate('/ziprequests/neu')} >Suche</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigate('/activity')} >Activity</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                }
                <Menu.Menu position='right'>
                    {
                        user && getLoggedInComponent()
                    }
                </Menu.Menu>

            </Container>
        </Menu>
    )
}

export default Navbar