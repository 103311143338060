import React from 'react'
import { Button, Table } from 'semantic-ui-react'

const FileDropResult = ({fileDropResult, onMakeRequest}) => {

    const fileDataOk = (fileData) => {
        if (fileData.characterCount < 999000 &&  fileData.fileSize < 29.5) {
            return true;
        }
        return false;
    }

    const getFileIsOkComponent = (fileData) => {
        if (fileDataOk(fileData)) {
            return <Button positive onClick={() => onMakeRequest(fileData)}>Upload</Button>
        }
        return <span>Not OK</span>
    }

    return (
        <div>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>File Name</Table.HeaderCell>
                        <Table.HeaderCell>Characters</Table.HeaderCell>
                        <Table.HeaderCell>Size</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        <Table.Row>
                            <Table.Cell>{fileDropResult.file.name}</Table.Cell>
                            <Table.Cell>{fileDropResult.characterCount}</Table.Cell>
                            <Table.Cell>{fileDropResult.fileSize} MB</Table.Cell>
                            <Table.Cell>{getFileIsOkComponent(fileDropResult)}</Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>
        </div>
    )
}

export default FileDropResult