import React, { useMemo } from 'react'
import BaseTableGlobalFilter from './BaseTableGlobalFilter';
import CheckedCell from '../layout/CheckedCell';
import { Checkbox, Message } from 'semantic-ui-react';

const FilesTable = ({ data,setSelectedRows }) => {
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                    <Checkbox {...getToggleAllRowsSelectedProps()} />
                ),
                Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
            },
            {
                Header: 'IAN',
                accessor: 'ian',
                sortType: 'basic',
            },
            {
                Header: 'Charge',
                accessor: 'charge',
                sortType: 'basic'
            },
            {
                Header: 'EKL',
                accessor: 'buyerShortCode',
                sortType: 'none',
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortType: 'basic',
            },
            {
                Header: 'Last Update in VP',
                accessor: 'lastUpdateInVp',
                sortType: 'basic'
            },
            {
                Header: 'Excel?',
                accessor: 'hasExcel',
                sortType: 'basic',
                Cell: ({ value }) => <CheckedCell value={value} />
            },
            {
                Header: 'Überstezung?',  
                accessor: 'hasExcelEn',
                sortType: 'basic',
                Cell: ({ value }) => <CheckedCell value={value} />
            }
        ],
        []
    );


    if (!data || data.length === 0) {
        return (
            <div>
                <p>Keine Dateien vorhanden</p>
            </div>
        )
    }
    return (
        <div>
            <Message info content="Wähle nichts aus, um alles herunterzuladen"/>
            <BaseTableGlobalFilter columns={columns} data={data} setSelectedRows={setSelectedRows} />
        </div>
    )
}

export default FilesTable