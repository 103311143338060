
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainApp from "./MainApp";
import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
  return (
    <UserAuthContextProvider>
        <ToastContainer />
        <MainApp />
    </UserAuthContextProvider>
  );
}

export default App;
