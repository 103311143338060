import React from 'react';
import { Divider, Header } from 'semantic-ui-react';

const ContentHeading = ({ title }) => {
  return (
    <>
      <Header as='h3'>{title}</Header>
      <Divider section />
    </>
  );
};

export default ContentHeading;