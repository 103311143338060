import React, { useState } from 'react';
import { Form, Button, Segment, Header, Grid, Message } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikTextInput from './FormikTextInput';
import { useUserAuth } from '../../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';

const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
});

const LoginForm = () => {

    const { logIn } = useUserAuth();
    const navigate = useNavigate();
    const [error, setError] = useState("");


    const handleLogin = async (values, setSubmitting) => {
        try {
            await logIn(values.email, values.password);
            setSubmitting(false);
            navigate("/");
        } catch (err) {
            setError(err.message ? err.message : "Login Error");
            setSubmitting(false);
        }
    }


    return (
        <Grid centered columns={2}>
            <Grid.Column>
                <Segment clearing padded="very">
                    <Header as="h2" textAlign="left">Einloggen</Header>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={LoginSchema}                        
                    >
                    {({ isSubmitting, setSubmitting, values }) => (
                        <Form onSubmit={() => handleLogin(values, setSubmitting) }>
                                <FormikTextInput name="email" label="Email" placeholder="Email" />
                                <FormikTextInput name="password" label="Kennwort" placeholder="Kennwort" type="password" />
                                <Button type="submit" positive loading={isSubmitting}>Submit</Button>
                            </Form>
                        )}
                    </Formik>
                    {
                        error && <Message
                            error
                            header='Error'
                            content={error}
                        />
                    }
                </Segment >
            </Grid.Column>
        </Grid>
    );
};

export default LoginForm;
