import React, { useCallback, useState } from 'react';
import ContentHeading from '../components/layout/ContentHeading';
import { Button } from 'semantic-ui-react';
import NewTranslationDocumentForm from '../components/forms/NewTranslationDocumentForm';
import getEndpoints from '../api/endpoints';
import BriefingDocumentsList from '../components/BriefingDocumentsList';

const DocumentTranslationOverView = () => {
    const [formOpen, setFormOpen] = useState(false);
    const [refreshDocumentList, setRefreshDocumentList] = useState(() => () => { });

    document.title = 'Dokumentübersetzung';

    const onFileLoaded = async (error) => {
        setFormOpen(false);
        if (error) {
            alert('Error uploading document');
            console.error(error);
            return;
        } else {
            alert('Dokument erfolgreich hochgeladen');
            refreshDocumentList(false);
        }
    };

    const onDownloadRequest = async (documentId, documentKey, documentName) => {
        try {
            const ep = await getEndpoints();
            const response = await ep.downloadBriefingDocument(documentId, documentKey);
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            refreshDocumentList(false);
        } catch (error) {
            alert('Error downloading document');
            console.error(error);
        }
    };

    const onRefreshCallback = useCallback((refreshFunction) => {
        setRefreshDocumentList(() => refreshFunction);
    }, []);

    return (
        <div>
            <ContentHeading title='Dokumentübersetzung' />
            <div style={{ marginBottom: '1rem' }}>
                {formOpen ? (
                    <NewTranslationDocumentForm onFileLoaded={onFileLoaded} onCancel={() => setFormOpen(false)} />
                ) : (
                    <Button primary onClick={() => setFormOpen(true)}>Neues Dokument</Button>
                )}
            </div>
            <BriefingDocumentsList
                onDownloadRequest={onDownloadRequest}
                onRefreshCallback={onRefreshCallback} />
        </div>
    );
};

export default DocumentTranslationOverView;