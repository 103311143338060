import { Message, Form, Button, Segment, Header } from 'semantic-ui-react';
import { useUserAuth } from '../../context/UserAuthContext';
import { useState } from 'react';
import { Formik } from 'formik';
import { updateProfile } from "firebase/auth";
import * as Yup from 'yup';
import FormikTextInput from './FormikTextInput';

const validationSchema = Yup.object({
    displayName: Yup.string()
        .min(4, "Display Name must be at least 4 characters")
        .required("Required"),
});


const DisplayNameChange = () => {
    const { user } = useUserAuth();
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);


    const handleSubmit = (values, setSubmitting, resetForm) => {
        setSuccess(false);    
        setError(null);
        updateProfile(user, { displayName: values.displayName })
            .then(() => {
                resetForm();
                setSubmitting(false);
                setSuccess(true);
                setError(null);
                // You might want to refresh your user context here
            })
            .catch((error) => {
                setSubmitting(false);
                setSuccess(false);
                setError(error.message);
            });
    }

    return (
        <Segment clearing padded="very">
            <Formik
                initialValues={{ displayName: user.displayName || '' }}
                validationSchema={validationSchema}
            >
                {({ values, isSubmitting, setSubmitting, resetForm }) => (
                    <Form onSubmit={() => handleSubmit(values, setSubmitting, resetForm)}>
                        <Header as="h4" textAlign="left">Anzeigenamen ändern</Header>
                        <FormikTextInput type="text" name="displayName" placeholder="Anzeigename" />
                        <Button type="submit" positive loading={isSubmitting}>Aktualisieren</Button>
                    </Form>
                )}
            </Formik>

            {success && <Message success header='Aktualisierung erfolgreich' content="Ihr Anzeigename wurde erfolgreich aktualisiert." />}
            {error && <Message negative header='Aktualisierung fehlgeschlagen' content={error} />}
        </Segment>
    );
};

export default DisplayNameChange;
