import React from 'react'
import LoginForm from '../components/forms/LoginForm'

const Login = () => {

    document.title = "Einloggen";

    return (
        <div>
            <LoginForm />
        </div>
    )
}

export default Login