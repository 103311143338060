import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Loader } from 'semantic-ui-react';
import * as XLSX from 'xlsx';

const FileDropZone = ({ onFileDrop }) => {
    const [loading, setLoading] = useState(false);

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xlsm'],
            'application/vnd.ms-excel': ['.xls']
        },
        onDrop: async (acceptedFiles) => {
            setLoading(true);
            const file = acceptedFiles[0];


            try {
                // Read the Excel file
                const arrayBuffer = await file.arrayBuffer();
                const workbook = XLSX.read(arrayBuffer, { type: 'array' });

                // Initialize character count
                let totalCharacters = 0;

                // Loop through all sheets
                workbook.SheetNames.forEach(sheetName => {
                    const sheet = workbook.Sheets[sheetName];
                    const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

                    // Loop through each row and cell
                    sheetData.forEach(row => {
                        row.forEach(cell => {
                            if (typeof cell === 'string') {
                                totalCharacters += cell.length;
                            } else if (typeof cell === 'number') {
                                totalCharacters += cell.toString().length;
                            }
                        });
                    });
                });

                setLoading(false);
                onFileDrop({                    
                    file: file,
                    fileSize: (file.size / 1024 / 1024).toFixed(2),
                    characterCount: totalCharacters
                });
            } catch (err) {
                setLoading(false);
                alert('Error reading file:', err);
            }
        }
    });

    return (
        <div {...getRootProps()} className='drag-and-drop-container'>
            <input {...getInputProps()} />
            {loading ? (
                <Loader active inline='centered' />
            ) : (
                <>
                    <p>Excel-Dateien hierher ziehen oder klicken, um Dateien auszuwählen</p>
                </>
            )}
        </div>
    );
}

export default FileDropZone;
