import React from 'react';
import { useUserAuth } from '../context/UserAuthContext'
import PasswordChange from '../components/forms/PasswordChange'
import DisplayNameChange from '../components/forms/DisplayNameChange';
import { Divider } from 'semantic-ui-react';


const UserProfile = () => {
    const { user } = useUserAuth();

    document.title = user.displayName;



    return (
        <div>
                <h1>Profil</h1>
                <Divider />
                {user && <><h3>Hallo, {user.displayName ? user.displayName : "Bitte ein Profilename setzen.."}</h3>
                </>}


            <DisplayNameChange />
            <PasswordChange />
        </div>
    );
};

export default UserProfile;