import React, { useState, useEffect, useCallback } from 'react';
import { Button, Divider, Icon } from 'semantic-ui-react';
import getEndpoints from '../api/endpoints';
import BriefingDocumentsTable from './tables/BriefingDocumentsTable';



const BriefingDocumentsList = ({ onDownloadRequest, onRefreshCallback }) => {
    const [documentsList, setDocumentsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [autoRefresh, setAutoRefresh] = useState(true);
    const [lastRefreshTime, setLastRefreshTime] = useState(null);

    const fetchData = useCallback(async (showLoading = true) => {
        if (showLoading) setLoading(true);
        try {
            const Endpoints = await getEndpoints();
            const data = await Endpoints.getBriefingDocuments();
            setDocumentsList(data);
            setLastRefreshTime(new Date());
        } catch (error) {
            console.error("getBriefingDocuments load error", error);
        } finally {
            if (showLoading) setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();

        let interval;
        if (autoRefresh) {
            interval = setInterval(() => {
                fetchData(false);
            }, 30000); 
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [fetchData, autoRefresh]);

    useEffect(() => {
        onRefreshCallback(fetchData);
    }, [fetchData, onRefreshCallback]);

    const handleManualRefresh = () => {
        fetchData(true);
    };

    const toggleAutoRefresh = () => {
        setAutoRefresh(!autoRefresh);
    };

    return (
        <div>
            <Divider section/>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button icon labelPosition='left' onClick={handleManualRefresh} disabled={loading}>
                    <Icon name='refresh' />
                    Aktualisieren
                </Button>
                <Button toggle active={autoRefresh} onClick={toggleAutoRefresh}>
                    Auto-refresh {autoRefresh ? 'deaktivieren' : 'aktivieren'}
                </Button>
                {lastRefreshTime && (
                    <span>
                        letzte Aktualisierung: {lastRefreshTime.toLocaleTimeString()}
                    </span>
                )}
            </div>
            <BriefingDocumentsTable
                documentsList={documentsList} 
                onDownloadRequest={onDownloadRequest}
                loading={loading}
            />
        </div>
    );
};

export default BriefingDocumentsList;