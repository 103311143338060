import React, { useMemo } from 'react'
import { Button, ButtonGroup, ButtonOr, Icon, } from 'semantic-ui-react';
import { convertToEuropeanDate } from '../../utis/dateConversions';
import { useNavigate } from 'react-router-dom';
import BaseTableGlobalFilter from './BaseTableGlobalFilter';

const formatFilterSummary = (filterSummary) => {
    let summary = '';
    for (const key in filterSummary) {
        if (Array.isArray(filterSummary[key])) {
            summary += `${key}: ${filterSummary[key].join(', ')}, `;
        } else {
            summary += `${key}: ${filterSummary[key]}, `;
        }
    }
    return summary.slice(0, -2); // remove trailing comma and space
};

const ZipRequestsTable = ({ tableData, onDeleteZipRequest, onDownloadZipRequest }) => {

    const nav = useNavigate();

    const columns = useMemo(
        () => [
            {
                Header: 'Datum',
                accessor: 'created',
                Cell: ({ row }) => <span>{convertToEuropeanDate(row.original.created)}</span>
            },
            {
                Header: 'User',
                accessor: 'userName',
                sortType: 'basic',
            },
            {
                Header: 'State',
                accessor: 'status',
                sortType: 'basic',
            },
            {
                Header: 'Filters',
                accessor: 'filterSummary',
                sortType: 'basic',
                Cell: ({ value }) => (
                    <div>
                        {formatFilterSummary(value)}
                    </div>
                ),

            },
            {
                Header: 'Download',
                accessor: 'downloadLink',
                Cell: ({ row }) => {
                    //console.log(row);
                    return (
                        <>
                            {row.original.downloadLink && row.original.downloadLink !== "" && (
                                <ButtonGroup>
                                    <Button
                                        color='blue'
                                        icon="download"
                                        onClick={() => {
                                            onDownloadZipRequest(row.original.zipRequestId)
                                        }}
                                    /><ButtonOr />
                                    <Button
                                        color='red'
                                        icon="trash"
                                        onClick={() => {
                                            onDeleteZipRequest(row.original.zipRequestId)
                                        }}
                                    />
                                </ButtonGroup>
                            )}
                        </>
                    );
                }
            }
        ],
        []
    );


    if (!tableData || tableData.length === 0) {
        return (
            <div>
                <p>Keine Ativitäten vorhanden</p>
            </div>
        )
    }

    return (
        <div>
            <BaseTableGlobalFilter columns={columns} data={tableData} />
        </div>
    )
}

export default ZipRequestsTable